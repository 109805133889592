
































import Vue from "vue";
import store from "../../store/shop";
import moment from "moment";
import ShopAPI from "../../common/api/api_shop";
const api = new ShopAPI();
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";
const translate = TranslateServiceBuilder.Instance()._;
import notificationMixin from "../../mixin/notification";
import { ServedCode } from "../../model/entity/orderSetting";
import { OrderStatus } from "../../model/entity/orderSetting";

export default Vue.component("HOOrderList", {
  mixins: [notificationMixin],
  data() {
    return {
      servedOrder: ServedCode.SERVED_ORDER,
      servedReady: ServedCode.SERVED_READY,
      servedDone: ServedCode.SERVED_DONE,
      servedCancel: ServedCode.SERVED_CANCEL,
      statusCancel: OrderStatus.STATUS_CANCELED,
    };
  },
  props: {
    served: Number,
  },
  computed: {
    orders() {
      return store.state.orderList;
    },
    loading() {
      return store.state.loading;
    },
  },
  mounted() {
    api.set_token(store.state.token);
    this.fetchOrderList();
  },
  methods: {
    fetchOrderList() {
      store.dispatch("fetchOrderList", { served: this.served });
    },
    getOrderType: function(isDeliver) {
      return isDeliver ? "delivery" : "";
    },
    getOrderTime:function(order) {
      return moment(order.created).format("HH:mm");
    },
    isShowNextStepButton:function() {
      return this.served == 1 || this.served == 2;
    },
    updateServed:function(order, served) {
      order.is_served = served;
      if (served === this.servedCancel) {
        order.status = this.statusCancel;
      }
      api
        .order_served({
          datas: { order: order },
        })
        .then((response) => {
          let message =
            response.data.message || "Success";
          this.showSuccessNotification(message);

          this.fetchOrderList();
        })
        .catch((err) => {
          let message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.isLoading = false;
        });
      return moment(order.created).format("HH:mm");
    },
    getSeatName(order) {
      if (order.table_session && order.table_session.table_seat) {
        return order.table_session.table_seat.name;
      }
      return "";
    }
  },
});
